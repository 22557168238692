import React from "react";

import { successMessage } from "../../css/contact-form.module.css";

const SendSuccess = ({ message }) => {
  return (
    <div className={successMessage}>
      <h3>Thank you for your message</h3>
      <p>{message}</p>
    </div>
  );
};

export default SendSuccess;
