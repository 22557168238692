import React from "react";

import ContactForm from "./contactForm";

const ContactDetails = ({ tel, bookingContacts, showSocial }) => {
  return (
    <>
      {firstParagraph(bookingContacts)}
      {showSocial ? (
        <p>
          You can also follow us{" "}
          <a href="https://www.facebook.com/PannalVillageHall">on Facebook</a>{" "}
          or{" "}
          <a href="https://www.instagram.com/pannalvillagehall/">
            on Instagram
          </a>
          .
        </p>
      ) : null}
      <ContactForm />
    </>
  );
};

const firstParagraph = (contacts) => {
  if (contacts.length === 0) {
    return (
      <p>
        Email us at{" "}
        <a href="mailto:pannalvillagehall@gmail.com">
          pannalvillagehall@gmail.com
        </a>
        , or send us a message below.
      </p>
    );
  } else if (contacts.length === 1) {
    return (
      <p>
        Call {contacts[0].name} on{" "}
        <a href={`tel:${contacts[0].phoneNumber}`}>{contacts[0].phoneNumber}</a>
        , email us at{" "}
        <a href="mailto:pannalvillagehall@gmail.com">
          pannalvillagehall@gmail.com
        </a>
        , or send us a message below.
      </p>
    );
  } else {
    return (
      <p>
        Call {contacts[0].name} on{" "}
        <a href={`tel:${contacts[0].phoneNumber}`}>{contacts[0].phoneNumber}</a>{" "}
        or {contacts[1].name} on{" "}
        <a href={`tel:${contacts[1].phoneNumber}`}>{contacts[1].phoneNumber}</a>
        , email us at{" "}
        <a href="mailto:pannalvillagehall@gmail.com">
          pannalvillagehall@gmail.com
        </a>
        , or send us a message below.
      </p>
    );
  }
};

export default ContactDetails;
