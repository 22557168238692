import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";

import GetInTouch from "../components/contact/contactUs";

import { contactWrapper } from "../css/contact.module.css";

const Contact = ({ data }) => (
  <>
    <Helmet>
      <title>Bookings | Pannal Village Hall</title>
    </Helmet>
    <div className="page-header">
      <h1>Bookings</h1>
    </div>
    <div className={contactWrapper}>
      <h2>Get in touch</h2>
      <p>
        Send us your provisional booking needs with dates and times so we can
        try to accommodate you – please check{" "}
        <Link to="/calendar">the calendar</Link> first to make sure your slot is
        available.
      </p>
      <GetInTouch
        tel={data.strapiContactUs.telephoneNumber}
        bookingContacts={data.allStrapiBookingContacts.edges.map(
          (item) => item.node
        )}
      />
    </div>
  </>
);

export default Contact;

export const query = graphql`
  {
    allStrapiBookingContacts {
      edges {
        node {
          name
          phoneNumber
        }
      }
    }
    strapiContactUs {
      addressText
      contactText
      telephoneNumber
    }
  }
`;
