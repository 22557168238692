import React, { useState } from "react";
import { Form, Field } from "react-final-form";

import SendSuccess from "./sendSuccess";

import {
  contactGetUpdates,
  contactForm,
} from "../../css/contact-form.module.css";
import { sendContactMessage } from "../../actions/email";

const initialState = {
  error: false,
  message: "",
  submitting: false,
  success: false,
};

const ContactForm = () => {
  const [subValues, setSubValues] = useState(initialState);

  const onSubmit = async (values, form) => {
    setSubValues({ ...subValues, submitting: true });
    const result = await sendContactMessage(values);
    if (result) {
      setSubValues({
        error: false,
        message: `We will get back to you within 48 hours`,
        submitting: false,
        success: true,
      });
      setTimeout(() => {
        setSubValues(initialState);
      }, 3000);
    }
  };

  const required = (value) => (value ? undefined : "Required");

  return (
    <>
      {subValues.success ? (
        <SendSuccess message={subValues.message} />
      ) : (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={contactForm}>
              <Field name="name" validate={required}>
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="contact-name">Name (required)</label>
                    <input {...input} type="text" id="contact-name" required />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span>{meta.error || meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>
              <Field name="subject" validate={required}>
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="contact-subject">Subject (required)</label>
                    <input
                      {...input}
                      type="text"
                      id="contact-subject"
                      required
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span>{meta.error || meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>

              <Field name="email" validate={required}>
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="contact-email">Email (required)</label>
                    <input
                      {...input}
                      type="email"
                      id="contact-email"
                      required
                    />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span>{meta.error || meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>

              <Field name="phone">
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="contact-phone-number">Phone number</label>
                    <input {...input} id="contact-phone-number" type="tel" />
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span>{meta.error || meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>

              <div>
                <label htmlFor="contact-message">Message (required)</label>
                <Field
                  name="message"
                  component="textarea"
                  id="contact-message"
                  required
                />
              </div>

              <Field name="get-updates" type="checkbox">
                {({ input, meta }) => (
                  <div className={contactGetUpdates}>
                    <input
                      {...input}
                      id="contact-get-updates"
                      type="checkbox"
                    />
                    <label htmlFor="contact-get-updates">
                      Would you like to get regular updates from us?
                    </label>
                    {(meta.error || meta.submitError) && meta.touched && (
                      <span>{meta.error || meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>

              <button
                type="submit"
                disabled={subValues.submitting || subValues.success}
                className="btn-primary"
              >
                Send message
              </button>
            </form>
          )}
        />
      )}
    </>
  );
};

export default ContactForm;
